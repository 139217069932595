import {Injectable} from '@angular/core';
import {HttpClient, HttpParams} from '@angular/common/http';
import {Observable} from 'rxjs';
import {Customer, CustomerCreateRequest, CustomerUpdateRequest,} from './customer';
import {environment} from '../../environments/environment';

@Injectable()
export class CustomerService {
    constructor(private http: HttpClient) {
    }

    createCustomer(createRequest: CustomerCreateRequest): Observable<any> {
        return this.http.post(
            environment.backendUrl + '/api/customers',
            createRequest,
            {responseType: 'text'}
        );
    }

    updateCustomer(
        id: Number,
        updateRequest: CustomerUpdateRequest
    ): Observable<any> {
        return this.http.post(
            environment.backendUrl + '/api/customers/' + id,
            updateRequest,
            {responseType: 'text'}
        );
    }

    readCustomers(
        pageNumber?: Number,
        pageSize?: Number
    ): Observable<Customer[]> {
        let queryParams = new HttpParams();
        if (pageNumber && pageSize) {
            queryParams = queryParams
                .append('pageNumber', pageNumber.toString())
                .append('pageSize', pageSize.toString());
        }
        queryParams = queryParams.append('includeDisabled', true);

        return this.http.get<Customer[]>(
            `${environment.backendUrl}/api/customers`,
            {
                params: queryParams
            }
        );
    }

    readActiveCustomers(): Observable<Customer[]> {
        return this.http.get<Customer[]>(
            environment.backendUrl + '/api/customers'
        );
    }
}
