import { Component, Inject, OnInit } from "@angular/core";
import { Mode } from "../../mode";
import {
    AbsenceDaySegment,
    AbsenceDTO,
    AbsenceModel,
    AbsenceRequest,
    AbsenceType,
} from "../absence";
import { UserDetails } from "../../users/userDetails";
import { UserService } from "../../users/user.service";
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material/dialog";
import { AbsenceService } from "../absence.service";
import { EnumUtil, TimeRangeUtil } from "../../common";

@Component({
    selector: "app-create-absence-dialog",
    templateUrl: "./create-absence-dialog.component.html",
    styleUrls: ["./create-absence-dialog.component.sass"],
})
export class CreateAbsenceDialogComponent implements OnInit {
    mode: Mode;
    Mode = Mode;
    hrRole = true;

    AbsenceDaySegment = AbsenceDaySegment;
    AbsenceType = AbsenceType;

    model: AbsenceModel;
    availableUsers: UserDetails[] = [];
    TimeRangeUtil = TimeRangeUtil;

    constructor(
        private userService: UserService,
        private absenceService: AbsenceService,
        @Inject(MAT_DIALOG_DATA) public data: CreateAbsenceDialogComponentData,
        private dialogRef: MatDialogRef<AbsenceDTO>
    ) {
        if (data) {
            this.model = new AbsenceModel(
                data.absence.id,
                data.absence.start,
                data.absence.startDateDaySegment,
                data.absence.end,
                data.absence.endDateDaySegment,
                data.absence.user.principalId,
                data.absence.user.displayName,
                data.absence.type
            );
            this.mode = data.mode;
            this.hrRole = data.hrRole;
        } else {
            this.model = new AbsenceModel();
            this.mode = Mode.CREATE;
        }
    }

    ngOnInit(): void {
        this.userService
            .readActiveUsers()
            .subscribe((user) => (this.availableUsers = user));
    }

    saveAbsence(): void {
        const absence: AbsenceRequest = {
            userId: this.model.user.principalId,
            startDate: [
                this.model.start.year(),
                this.model.start.month() + 1,
                this.model.start.date(),
            ],
            startDateDaySegment: EnumUtil.getKeyByValue(
                AbsenceDaySegment,
                this.model.startDateDaySegment
            ),
            endDate: [
                this.model.end.year(),
                this.model.end.month() + 1,
                this.model.end.date(),
            ],
            endDateDaySegment: this.model.endDateDaySegment
                ? EnumUtil.getKeyByValue(
                      AbsenceDaySegment,
                      this.model.endDateDaySegment
                  )
                : null,
            type: EnumUtil.getKeyByValue(AbsenceType, this.model.type),
        };

        if (this.mode === Mode.CREATE) {
            this.absenceService.saveAbsence(absence).subscribe({
                complete: () => this.dialogRef.close(absence),
                error: (err) => console.log(err),
            });
        } else {
            this.absenceService
                .updateAbsence(this.model.id, absence)
                .subscribe({
                    complete: () => this.dialogRef.close(absence),
                    error: (err) => console.log(err),
                });
        }
    }

    deleteAbsence(): void {
        this.absenceService.deleteAbsence(this.model.id).subscribe({
            complete: () => this.dialogRef.close(),
            error: (err) => console.log(err),
        });
    }

    checkAndChangeDaySegments(): void {
        if (this.model.end != null && this.model.start != null) {
            if (
                this.mode === Mode.CREATE &&
                this.model.startDateDaySegment == null
            ) {
                this.model.startDateDaySegment = AbsenceDaySegment.WHOLE_DAY;
            }

            if (TimeRangeUtil.isSameDay(this.model.start, this.model.end)) {
                this.model.endDateDaySegment = null;
            } else {
                if (this.model.endDateDaySegment == null) {
                    this.model.endDateDaySegment = AbsenceDaySegment.WHOLE_DAY;
                }

                if (
                    this.model.startDateDaySegment ===
                    AbsenceDaySegment.FIRST_HALF
                ) {
                    this.model.startDateDaySegment =
                        AbsenceDaySegment.WHOLE_DAY;
                }
            }
        }
    }
}

export interface CreateAbsenceDialogComponentData {
    absence: AbsenceModel;
    mode: Mode;
    hrRole?: boolean;
}
