import { BrowserModule } from "@angular/platform-browser";
import { Inject, LOCALE_ID, NgModule } from "@angular/core";
import { AppComponent } from "./app.component";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { HTTP_INTERCEPTORS, HttpClientModule } from "@angular/common/http";
import { UsersModule } from "./users/users.module";
import { FlexLayoutModule } from "@angular/flex-layout";
import { ProjectsModule } from "./projects/projects.module";
import { MatMomentDateModule } from "@angular/material-moment-adapter";
import { TimetrackingModule } from "./timetracking/timetracking.module";
import { RouterModule, Routes } from "@angular/router";
import { registerLocaleData } from "@angular/common";
import localeDe from "@angular/common/locales/de";
import { ProjectAdminOverviewComponent } from "./projects/project-admin-overview/project-admin-overview.component";
import { UserOverviewComponent } from "./users/user-overview/user-overview.component";
import {
    CustomerAdminGuard,
    HrGuard,
    LoggedInGuard,
    ProjectAdminGuard,
    ReportingGuard,
    UserAdminGuard,
} from "./role-guard";
import { CustomerOverviewComponent } from "./customer/customer-overview/customer-overview.component";
import { CustomerModule } from "./customer/customer.module";
import { TimesheetOverviewComponent } from "./timetracking/timesheet-overview/timesheet-overview.component";
import { HomeComponent } from "./home/home.component";
import { environment } from "../environments/environment";
import { ReportingOverviewComponent } from "./reporting/reporting-overview/reporting-overview.component";
import { ReportingModule } from "./reporting/reporting.module";
import { MyProjectsOverviewComponent } from "./projects/project-overview/project-overview.component";
import { ProjectRolesOverviewComponent } from "./projects/project-roles-overview/project-roles-overview.component";
import { ProjectMemberOverviewComponent } from "./projects/project-admin-overview/project-member-overview/project-member-overview.component";
import { AbsenceModule } from "./absence/absence.module";
import { AbsenceOverviewComponent } from "./absence/absence-overview/absence-overview.component";
import { ConfirmDialogComponent } from "./common/confirm-dialog/confirm-dialog.component";
import { AbsenceSelfServiceOverviewComponent } from "./absence/absence-self-service-overview/absence-self-service-overview.component";
import { InfoSnackbarComponent } from "./common/info-snackbar/info-snackbar.component";
import { AbsenceAdminOverviewComponent } from "./absence/absence-admin-overview/absence-admin-overview.component";
import { AvailableVacationDayAdminOverviewComponent } from "./absence/available-vacation-day-admin-overview/available-vacation-day-admin-overview.component";
import { MatSidenavModule } from "@angular/material/sidenav";
import { MatToolbarModule } from "@angular/material/toolbar";
import { MatIconModule } from "@angular/material/icon";
import { MatMenuModule } from "@angular/material/menu";
import { MatSnackBarModule } from "@angular/material/snack-bar";
import { MatButtonModule } from "@angular/material/button";
import { MatDialogModule } from "@angular/material/dialog";
import { MatDividerModule } from "@angular/material/divider";
import { MatProgressSpinnerModule } from "@angular/material/progress-spinner";
import { BackendService } from "./backend.service";
import { MatCardModule } from "@angular/material/card";
import {MatSelectModule} from "@angular/material/select";
import { FormsModule } from "@angular/forms";
import { MatInputModule } from '@angular/material/input';
import { MatFormFieldModule } from "@angular/material/form-field";
import {
    MSAL_GUARD_CONFIG,
    MSAL_INSTANCE,
    MSAL_INTERCEPTOR_CONFIG,
    MsalBroadcastService,
    MsalGuard,
    MsalGuardConfiguration,
    MsalInterceptor,
    MsalInterceptorConfiguration,
    MsalModule,
    MsalRedirectComponent,
    MsalService,
} from "@azure/msal-angular";
import {
    BrowserCacheLocation,
    InteractionType,
    IPublicClientApplication,
    PublicClientApplication,
} from "@azure/msal-browser";
import { CanvasJSAngularChartsModule } from '@canvasjs/angular-charts';
import {RegionsModule} from './regions/regions.module';
import {RegionOverviewComponent} from './regions/region-overview/region-overview.component';
import {HolidayModule} from './holidays/holiday.module';
import {HolidayOverviewComponent} from './holidays/holiday-overview/holiday-overview.component';


// https://docs.microsoft.com/en-us/azure/active-directory/develop/tutorial-v2-angular-auth-code
const isIE =
    window.navigator.userAgent.indexOf("MSIE ") > -1 ||
    window.navigator.userAgent.indexOf("Trident/") > -1;

export function MSALInstanceFactory(): IPublicClientApplication {
    return new PublicClientApplication({
        auth: {
            clientId: "9da034f7-da16-4627-b07e-420b2741b805",
            authority:
                "https://login.microsoftonline.com/8fe54ffa-ec3c-4451-a2a2-03e4abda01d6",
            redirectUri: window.location.origin + "/index.html",
            postLogoutRedirectUri: window.location.origin + "/index.html",
            navigateToLoginRequestUrl: true,
        },
        cache: {
            cacheLocation: BrowserCacheLocation.LocalStorage,
            storeAuthStateInCookie: isIE, // Set to true for Internet Explorer 11
        },
    });
}

export function MSALInterceptorConfigFactory(): MsalInterceptorConfiguration {
    return {
        interactionType: InteractionType.Redirect,
        protectedResourceMap: new Map([
            ["https://graph.microsoft.com/v1.0/*", ["user.read"]],
            [
                environment.backendUrl + "/api/*",
                ["9da034f7-da16-4627-b07e-420b2741b805/.default"],
            ],
        ]),
    };
}

export function MSALGuardConfigFactory(): MsalGuardConfiguration {
    return {
        interactionType: InteractionType.Redirect,
        authRequest: {
            scopes: ["user.read"],
        },
        loginFailedRoute: "/index.html",
    };
}

const appRoutes: Routes = [
    { path: "", redirectTo: "timesheet", pathMatch: "full" },
    {
        path: "manage/users",
        component: UserOverviewComponent,
        canActivate: [UserAdminGuard],
    },
    {
        path: "manage/regions",
        component: RegionOverviewComponent,
        canActivate: [UserAdminGuard],
    },
    {
        path: "manage/holidays",
        component: HolidayOverviewComponent,
        canActivate: [UserAdminGuard],
    },
    {
        path: "manage/projects",
        component: ProjectAdminOverviewComponent,
        canActivate: [ProjectAdminGuard],
    },
    {
        path: "manage/projects/:projectId/members",
        component: ProjectMemberOverviewComponent,
        canActivate: [ProjectAdminGuard],
    },
    {
        path: "manage/project-roles",
        component: ProjectRolesOverviewComponent,
        canActivate: [ProjectAdminGuard],
    },
    {
        path: "manage/reporting",
        component: ReportingOverviewComponent,
        canActivate: [ReportingGuard],
    },
    {
        path: "manage/customers",
        component: CustomerOverviewComponent,
        canActivate: [CustomerAdminGuard],
    },
    {
        path: "manage/absences",
        component: AbsenceAdminOverviewComponent,
        canActivate: [HrGuard],
    },
    {
        path: "manage/available-vacation-days",
        component: AvailableVacationDayAdminOverviewComponent,
        canActivate: [HrGuard],
    },
    {
        path: "timesheet",
        component: TimesheetOverviewComponent,
        canActivate: [LoggedInGuard],
    },
    {
        path: "projects",
        component: MyProjectsOverviewComponent,
        canActivate: [LoggedInGuard],
    },
    {
        path: "absence",
        component: AbsenceOverviewComponent,
        canActivate: [LoggedInGuard],
    },
    {
        path: "absence/self-service",
        component: AbsenceSelfServiceOverviewComponent,
        canActivate: [LoggedInGuard],
    },
    { path: "home", component: HomeComponent, canActivate: [] },
];

@NgModule({
    declarations: [
        AppComponent,
        ConfirmDialogComponent,
        InfoSnackbarComponent,
    ],
    imports: [
        BrowserModule,
        RouterModule,
        FlexLayoutModule,
        BrowserAnimationsModule,
        ProjectsModule,
        CustomerModule,
        ReportingModule,
        UsersModule,
        RegionsModule,
        HolidayModule,
        TimetrackingModule,
        AbsenceModule,
        MatSidenavModule,
        MatButtonModule,
        MatSnackBarModule,
        MatToolbarModule,
        MatMomentDateModule,
        MatMenuModule,
        MatIconModule,
        RouterModule.forRoot(appRoutes, {}),
        MsalModule,
        HttpClientModule,
        MatDialogModule,
        MatDividerModule,
        MatProgressSpinnerModule,
        MatCardModule,
        MatSelectModule,
        CanvasJSAngularChartsModule,
        FormsModule,
        MatFormFieldModule,
        MatInputModule
    ],
    providers: [
        {
            provide: LOCALE_ID,
            useValue: "de-DE",
        },
        {
            provide: HTTP_INTERCEPTORS,
            useClass: MsalInterceptor,
            multi: true,
        },
        {
            provide: MSAL_INSTANCE,
            useFactory: MSALInstanceFactory,
        },
        {
            provide: MSAL_GUARD_CONFIG,
            useFactory: MSALGuardConfigFactory,
        },
        {
            provide: MSAL_INTERCEPTOR_CONFIG,
            useFactory: MSALInterceptorConfigFactory,
        },
        MsalGuard,
        MsalBroadcastService,
        MsalService,
        UserAdminGuard,
        ProjectAdminGuard,
        CustomerAdminGuard,
        LoggedInGuard,
        ReportingGuard,
        BackendService,
    ],
    bootstrap: [AppComponent, MsalRedirectComponent],
})
export class AppModule {
    constructor(@Inject(LOCALE_ID) locale: string) {
        registerLocaleData(localeDe);
    }
}
