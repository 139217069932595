<form #entryForm="ngForm">
    <h2 mat-dialog-title>{{ mode === Mode.EDIT ? "Edit" : "Create" }} entry</h2>

    <mat-dialog-content>
        <div fxLayout="row" fxLayoutAlign="flex-start">
            <mat-form-field fxFlex="1 0" subscriptSizing="dynamic">
                <mat-label>Date</mat-label>
                <input
                    matInput
                    id="entryDate"
                    name="entryDate"
                    [matDatepicker]="picker"
                    [(ngModel)]="dialogModel.date"
                    (dateChange)="updateAvailableProjects()"
                    required
                />
                <mat-datepicker-toggle matIconSuffix [for]="picker"></mat-datepicker-toggle>
                <mat-datepicker #picker></mat-datepicker>
            </mat-form-field>
            <div fxFlex="0 0 auto" style="margin-top: 10px">
                <button mat-icon-button (click)="addDays(-1)" style="margin-left: 5px">
                    <mat-icon>remove</mat-icon>
                </button>
                <button mat-icon-button (click)="addDays(1)" style="margin-left: 5px">
                    <mat-icon>add</mat-icon>
                </button>
            </div>
        </div>

        <div fxLayout="row">
            <mat-form-field fxFlex="" subscriptSizing="dynamic">
                <mat-label>Project</mat-label>
                <mat-select
                    required
                    id="project"
                    (selectionChange)="updateAvailableRoles()"
                    name="project"
                    [(ngModel)]="dialogModel.projectId">
                    <mat-option *ngFor="let project of availableProjects" [value]="project.id">
                        <p style="padding-top: 10px" mat-line>{{ project.customerShortName }} &nbsp;</p>
                        <p mat-line>{{ project.name }} {{ project.favorite ? "&hearts;" : "" }}</p>
                    </mat-option>
                </mat-select>
            </mat-form-field>
        </div>
        <div fxLayout="row">
            <mat-form-field fxFlex="" subscriptSizing="dynamic">
                <mat-label>Role</mat-label>
                <mat-select
                    required
                    id="projectRole"
                    name="projectRole"
                    [(ngModel)]="dialogModel.projectRoleId"
                >
                    <mat-option
                        *ngFor="let projectRole of availableProjectRoles"
                        [value]="projectRole.id"
                    >
                        {{ projectRole.name }}
                    </mat-option>
                </mat-select>
            </mat-form-field>
        </div>

        <mat-form-field style="width: 100%" subscriptSizing="dynamic">
            <mat-label>Task(s)</mat-label>
            <textarea
                matInput
                id="taskDescriptionField"
                name="taskDescriptionField"
                required
                maxlength="255"
                rows="4"
                [(ngModel)]="dialogModel.description"
            ></textarea>
        </mat-form-field>

        <mat-form-field style="width: 15%; padding-right: 10px" subscriptSizing="dynamic">
            <mat-label>Hours</mat-label>
            <mat-select
                id="hoursSelect"
                name="hoursSelect"
                [(ngModel)]="dialogModel.hours"
            >
                <mat-option [value]="0">0</mat-option>
                <mat-option [value]="1">1</mat-option>
                <mat-option [value]="2">2</mat-option>
                <mat-option [value]="3">3</mat-option>
                <mat-option [value]="4">4</mat-option>
                <mat-option [value]="5">5</mat-option>
                <mat-option [value]="6">6</mat-option>
                <mat-option [value]="7">7</mat-option>
                <mat-option [value]="8">8</mat-option>
                <mat-option [value]="9">9</mat-option>
                <mat-option [value]="10">10</mat-option>
                <mat-option [value]="11">11</mat-option>
                <mat-option [value]="12">12</mat-option>
                <mat-option [value]="13">13</mat-option>
                <mat-option [value]="14">14</mat-option>
                <mat-option [value]="15">15</mat-option>
                <mat-option [value]="16">16</mat-option>
                <mat-option [value]="17">17</mat-option>
                <mat-option [value]="18">18</mat-option>
                <mat-option [value]="19">19</mat-option>
                <mat-option [value]="20">20</mat-option>
                <mat-option [value]="21">21</mat-option>
                <mat-option [value]="22">22</mat-option>
                <mat-option [value]="23">23</mat-option>
                <mat-option [value]="24">24</mat-option>
            </mat-select>
        </mat-form-field>

        <mat-form-field style="width: 15%" subscriptSizing="dynamic">
            <mat-label>Minutes</mat-label>
            <mat-select
                id="minutesSelect"
                name="minutesSelect"
                [(ngModel)]="dialogModel.minutes"
            >
                <mat-option [value]="0">0</mat-option>
                <mat-option [value]="5">5</mat-option>
                <mat-option [value]="10">10</mat-option>
                <mat-option [value]="15">15</mat-option>
                <mat-option [value]="20">20</mat-option>
                <mat-option [value]="25">25</mat-option>
                <mat-option [value]="30">30</mat-option>
                <mat-option [value]="35">35</mat-option>
                <mat-option [value]="40">40</mat-option>
                <mat-option [value]="45">45</mat-option>
                <mat-option [value]="50">50</mat-option>
                <mat-option [value]="55">55</mat-option>
            </mat-select>
        </mat-form-field>
    </mat-dialog-content>
    <mat-dialog-actions>
        <button
            type="button"
            *ngIf="mode === Mode.EDIT"
            mat-stroked-button
            color="warn"
            (click)="deleteEntry()"
        >
            Delete
        </button>
        <button
            mat-stroked-button
            style="margin-left: 10px"
            [disabled]="
                !entryForm.valid || (!dialogModel.hours && !dialogModel.minutes)
            "
            (click)="saveEntry()"
        >
            Save
        </button>
        <button
            mat-stroked-button
            style="margin-left: 10px"
            [mat-dialog-close]="false"
        >
            Cancel
        </button>
    </mat-dialog-actions>
</form>
