import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {environment} from '../../environments/environment';
import {ProjectRoleAssignmentDTO, ProjectRoleDTO, ProjectRoleSaveDTO} from './project-role';
import {Observable} from 'rxjs';

@Injectable()
export class ProjectRoleService {
    constructor(private http: HttpClient) {
    }

    readAll(): Observable<ProjectRoleDTO[]> {
        return this.http.get<ProjectRoleDTO[]>(
            environment.backendUrl + '/api/projectroles'
        );
    }

    readAllAssignedProjectRoles(
        projectIds: number[]
    ): Observable<ProjectRoleDTO[]> {
        const params = {
            projectIds: projectIds.map((projectId) => projectId.toString()),
        };
        return this.http.get<ProjectRoleDTO[]>(
            environment.backendUrl + '/api/my/projectrole/assigned',
            {params: params}
        );
    }

    create(projectRole: ProjectRoleSaveDTO): Observable<any> {
        return this.http.post(
            environment.backendUrl + '/api/projectroles',
            projectRole,
            {responseType: 'text'}
        );
    }

    update(id: number, projectRole: ProjectRoleSaveDTO): Observable<any> {
        return this.http.post(
            environment.backendUrl + `/api/projectroles/${id}`,
            projectRole,
            {responseType: 'text'}
        );
    }

    delete(id: number): Observable<any> {
        return this.http.delete(
            environment.backendUrl + `/api/projectroles/${id}`,
            {responseType: 'text'}
        );
    }

    saveProjectRoleCompensation(roleId: number, projectId: number, compensation: number): Observable<any> {
        const params = {
            compensation: compensation,
        };

        return this.http.post(
            environment.backendUrl + `/api/projectrole/compensation/${projectId}/${roleId}`,
            null,
            {params}
        );
    }

    getAssignedRoles(projectId: number): Observable<ProjectRoleDTO[]> {
        return this.http.get<ProjectRoleDTO[]>(
            `${environment.backendUrl}/api/projectrole/assigned/${projectId}`
        );
    }

    removeRoleFromProject(roleId: number, projectId: number): Observable<any> {
        return this.http.delete(
            environment.backendUrl + `/api/projectroles/${projectId}/${roleId}`
        );
    }


    getAvailableRoles() {
        return this.http.get<ProjectRoleDTO[]>(
            `${environment.backendUrl}/api/projectroles`
        );
    }
}
