import { Component, OnDestroy, OnInit } from "@angular/core";
import { AbsenceModel, AbsenceType } from "../absence";
import {
    AbsenceFilter,
    AbsenceFilterParams,
    AbsenceService,
} from "../absence.service";
import { UserDetails } from "../../users/userDetails";
import { UserService } from "../../users/user.service";
import * as moment from "moment";
import {
    SchedulerRow,
    SchedulerRowContent,
} from "../../scheduler/scheduler.component";
import {
    VacationModel,
    VacationOverviewModel,
    VacationState,
} from "../vacation";
import { VacationService } from "../vacation.service";
import { MatDialog } from "@angular/material/dialog";
import { VerifyVacationDialogComponent } from "../verify-vacation-dialog/verify-vacation-dialog.component";
import {
    CreateAbsenceDialogComponent,
    CreateAbsenceDialogComponentData,
} from "../create-absence-dialog/create-absence-dialog.component";
import {
    CreateVacationRequestDialogComponent,
    CreateVacationRequestDialogData,
} from "../create-vacation-request-dialog/create-vacation-request-dialog.component";
import { Mode } from "../../mode";
import { Subscription } from "rxjs";
import { ActivatedRoute, Router } from "@angular/router";
import {TimeRange} from '../../common';

@Component({
    selector: "app-absence-admin-overview",
    templateUrl: "./absence-admin-overview.component.html",
    styleUrls: ["./absence-admin-overview.component.sass"],
})
export class AbsenceAdminOverviewComponent implements OnInit, OnDestroy {
    public Mode = Mode;

    private routeQueryParams: Subscription;
    private absences: AbsenceModel[];
    private vacationOverviewModels: VacationOverviewModel[];
    private absencesFilter: AbsenceFilter;
    private availableUsers: UserDetails[];
    year: number;
    schedulerRows: SchedulerRow[];
    correction_mode: boolean;

    constructor(
        private absenceService: AbsenceService,
        private vacationService: VacationService,
        private userService: UserService,
        private dialog: MatDialog,
        private route: ActivatedRoute,
        private router: Router
    ) {
        this.availableUsers = [];
        this.absencesFilter = new AbsenceFilter();
        this.year = moment().year();
        this.correction_mode = false;
    }

    ngOnInit(): void {
        this.userService.readActiveUsers().subscribe((user) => {
            this.availableUsers = user;
            this.absencesFilter.selectedUser = this.availableUsers.map(
                (value) => value.principalId
            );
            this.reloadAbsences();
        });

        this.routeQueryParams = this.route.queryParams.subscribe((params) => {
            if (params["vacationId"]) {
                this.openVerifyVacationDialog(params["vacationId"]);
            }
        });
    }

    ngOnDestroy(): void {
        this.routeQueryParams.unsubscribe();
    }

    protected reloadAbsences(): void {
        const absenceFilterParams = new AbsenceFilterParams();
        this.absencesFilter.selectedTimeRange = new TimeRange(
            moment().set("year", this.year).startOf("year"),
            moment().set("year", this.year).endOf("year")
        );
        absenceFilterParams.userIds = this.absencesFilter.selectedUser;

        absenceFilterParams.startDay =
            this.absencesFilter.selectedTimeRange.start.date();
        absenceFilterParams.startMonth =
            this.absencesFilter.selectedTimeRange.start.month() + 1;
        absenceFilterParams.startYear =
            this.absencesFilter.selectedTimeRange.start.year();

        absenceFilterParams.endDay =
            this.absencesFilter.selectedTimeRange.end.date();
        absenceFilterParams.endMonth =
            this.absencesFilter.selectedTimeRange.end.month() + 1;
        absenceFilterParams.endYear =
            this.absencesFilter.selectedTimeRange.end.year();

        this.absenceService
            .readAbsencesWithoutVacationsByUserAndTimerange(absenceFilterParams)
            .subscribe((absenceDTOs) => {
                this.absences = absenceDTOs.map((absenceDTO) =>
                    AbsenceModel.fromAbsenceDTO(absenceDTO)
                );

                this.vacationService
                    .readVacationOverviewDTOsByUserAndTimerange(
                        absenceFilterParams
                    )
                    .subscribe((vacationOverviewDTOs) => {
                        this.vacationOverviewModels = vacationOverviewDTOs.map(
                            (vacationOverviewDTO) =>
                                VacationOverviewModel.fromVacationOverviewDTO(
                                    vacationOverviewDTO
                                )
                        );

                        this.schedulerRows = this.getSchedulerRows();
                    });
            });
    }

    private getSchedulerRows(): SchedulerRow[] {
        const schedulerRows: SchedulerRow[] = [];

        this.absencesFilter.selectedUser.forEach((user) => {
            const row: SchedulerRow = {
                name: this.availableUsers.find(
                    (availableUser) =>
                        availableUser.principalId === user.valueOf()
                ).displayName,
                information: user.valueOf(),
                content: [],
            };

            this.vacationOverviewModels.forEach((vacationOverviewModel) => {
                if (
                    vacationOverviewModel.user.principalId === row.information
                ) {
                    const content: SchedulerRowContent = {
                        id: vacationOverviewModel.id,
                        title: vacationOverviewModel.type,
                        start: vacationOverviewModel.start,
                        end: vacationOverviewModel.end,
                        clickable: true,
                    };

                    if (vacationOverviewModel.state === VacationState.OPEN) {
                        content.color = "#ff8b15";
                    }

                    if (vacationOverviewModel.state === VacationState.WIP) {
                        content.color = "#26ab9c";
                    }
                    if (
                        vacationOverviewModel.state === VacationState.DECLINED
                    ) {
                        content.color = "#FF0000";
                    }
                    row.content.push(content);
                }
            });

            this.absences.forEach((absence) => {
                if (absence.user.principalId === row.information) {
                    const content: SchedulerRowContent = {
                        id: absence.id,
                        title: absence.type,
                        start: absence.start,
                        end: absence.end,
                        color: "#696969",
                        clickable: true,
                    };
                    row.content.push(content);
                }
            });

            schedulerRows.push(row);
        });

        return schedulerRows;
    }

    changeMode(): void {
        this.correction_mode = !this.correction_mode;
    }

    editAbsence($event: SchedulerRowContent): void {
        if ($event.title === AbsenceType.VACATION) {
            this.correction_mode
                ? this.openEditVacationDialog($event.id)
                : this.openVerifyVacationDialog($event.id);
        } else {
            this.openEditAbsenceDialog($event.id);
        }
    }

    private openVerifyVacationDialog(vacationId: number): void {
        this.dialog
            .open(VerifyVacationDialogComponent, {
                data: vacationId,
                disableClose: true,
            })
            .afterClosed()
            .subscribe(() => {
                this.router.navigate(["."], { relativeTo: this.route });
                this.reloadAbsences();
            });
    }

    private openEditAbsenceDialog(absenceId: number): void {
        this.absenceService.readAbsenceById(absenceId).subscribe((value) => {
            const createAbsenceDialogComponentData: CreateAbsenceDialogComponentData =
                {
                    absence: AbsenceModel.fromAbsenceDTO(value),
                    mode: Mode.EDIT,
                };

            this.dialog
                .open(CreateAbsenceDialogComponent, {
                    data: createAbsenceDialogComponentData,
                    disableClose: true,
                })
                .afterClosed()
                .subscribe(() => this.reloadAbsences());
        });
    }

    private openEditVacationDialog(vacationId: number): void {
        this.vacationService
            .readVacationById(vacationId)
            .subscribe((vacationDTO) => {
                const createVacationRequestDialogData: CreateVacationRequestDialogData =
                    {
                        hrRole: true,
                        vacationModel:
                            VacationModel.fromVacationDTO(vacationDTO),
                        mode: Mode.EDIT,
                    };

                this.dialog
                    .open(CreateVacationRequestDialogComponent, {
                        data: createVacationRequestDialogData,
                        disableClose: true,
                    })
                    .afterClosed()
                    .subscribe(() => this.reloadAbsences());
            });
    }

    openCreateVacationDialog(): void {
        const createVacationRequestDialogData: CreateVacationRequestDialogData =
            {
                hrRole: true,
                mode: Mode.CREATE,
            };

        this.dialog
            .open(CreateVacationRequestDialogComponent, {
                data: createVacationRequestDialogData,
                disableClose: true,
            })
            .afterClosed()
            .subscribe(() => this.reloadAbsences());
    }

    openCreateAbscenceDialog(): void {
        this.dialog
            .open(CreateAbsenceDialogComponent, {
                data: null,
                disableClose: true,
            })
            .afterClosed()
            .subscribe(() => this.reloadAbsences());
    }
}
