import { Component, Inject, OnInit } from "@angular/core";
import { Project, ProjectCreateRequest, ProjectModel } from "../../project";
import { ProjectService } from "../../project.service";
import { ProjectAdminDeleteConfirmDialogComponent } from "../project-admin-delete-confirm-dialog/project-admin-delete-confirm-dialog.component";
import { Mode } from "../../../mode";
import { Customer } from "../../../customer/customer";
import { CustomerService } from "../../../customer/customer.service";
import {
    MAT_DIALOG_DATA,
    MatDialog,
    MatDialogRef,
} from "@angular/material/dialog";

@Component({
    selector: "app-create-project-dialog",
    templateUrl: "./create-project-dialog.component.html",
    styleUrls: ["./create-project-dialog.component.sass"],
})
export class CreateProjectDialogComponent implements OnInit {
    public model: ProjectModel;
    public mode: Mode;
    public Mode = Mode; // For template

    public availableCustomers: Customer[] = [];

    constructor(
        private projectService: ProjectService,
        @Inject(MAT_DIALOG_DATA) public project: ProjectModel,
        private dialogRef: MatDialogRef<Project>,
        private dialog: MatDialog,
        private customerService: CustomerService
    ) {
        if (project) {
            this.model = new ProjectModel(
                project.id,
                project.customerId,
                project.customerName,
                project.name,
                project.start,
                project.end,
                project.entryHint
            );
            this.mode = Mode.EDIT;
        } else {
            this.model = new ProjectModel();
            this.mode = Mode.CREATE;
        }
    }

    ngOnInit() {
        this.customerService
            .readActiveCustomers()
            .subscribe(
                (next) =>
                    (this.availableCustomers = next.sort((a, b) =>
                        a.name > b.name ? 1 : -1
                    ))
            );
    }

    saveProject() {
        const project: ProjectCreateRequest = {
            customerId: this.model.customerId,
            name: this.model.name,
            start: [
                this.model.start.year(),
                this.model.start.month() + 1,
                this.model.start.date(),
            ],
            end: [
                this.model.end.year(),
                this.model.end.month() + 1,
                this.model.end.date(),
            ],
            entryHint: this.model.entryHint,
        };

        if (this.mode === Mode.CREATE) {
            this.projectService.saveProject(project).subscribe({
                complete: () => this.dialogRef.close(project),
                error: (err) => console.log(err),
            });
        } else {
            this.projectService
                .updateProject(this.model.id, project)
                .subscribe({
                    complete: () => this.dialogRef.close(project),
                    error: (err) => console.log(err),
                });
        }
    }

    deleteProject() {
        this.dialog
            .open(ProjectAdminDeleteConfirmDialogComponent, {
                data: this.model,
            })
            .afterClosed()
            .subscribe((value) => {
                if (value) {
                    this.projectService.deleteProject(this.model.id).subscribe({
                        complete: () => this.dialogRef.close("deleted"),
                        error: (err) => console.log(err),
                    });
                }
            });
    }
}
