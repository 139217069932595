import { Component, OnInit, Injectable } from "@angular/core";
import * as moment from "moment";
import { Moment } from "moment";
import { TimeRangeUtil } from "../../common";
import { UserService } from "../../users/user.service";
import {
    ExcelExportParams,
    ExportParams,
    PdfExportParams,
    ReportingService,
} from "../reporting.service";
import * as FileSaver from "file-saver";
import { UserDetails } from "../../users/userDetails";
import { CustomerService } from "../../customer/customer.service";
import { Customer } from "../../customer/customer";
import {MatSnackBar} from '@angular/material/snack-bar';

@Component({
    selector: "app-reporting-overview",
    templateUrl: "./reporting-overview.component.html",
    styleUrls: ["./reporting-overview.component.sass"],
})

@Injectable({
  providedIn: 'root',
})
export class ReportingOverviewComponent implements OnInit {
    public pdfExportModel: PdfExportModel = new PdfExportModel();
    public excelExportModel: ExcelExportModel = new ExcelExportModel();

    public availableUsers: Object = [];
    public availableCustomers: Customer[];

    constructor(
        private userService: UserService,
        private customerService: CustomerService,
        private reportingService: ReportingService,
        private snackBar: MatSnackBar
    ) {}

    ngOnInit() {
        this.userService
            .readActiveUsers()
            .subscribe((next) => (this.availableUsers = next));
        this.customerService
            .readActiveCustomers()
            .subscribe((next) => (this.availableCustomers = next));
        this.setStartAndEndDateCurrentMonth(this.pdfExportModel);
    }

    downloadByUser() {
        const exportParams = this.getPdfExportParams(this.pdfExportModel);
        const filename = `Timesheets ${
            this.pdfExportModel.user.displayName
        }, ${this.getFilenameTimestamp(exportParams)}.zip`;

        this.reportingService
            .downloadReportByUser(
                this.pdfExportModel.user.principalId,
                exportParams
            )
            .subscribe(
                (blob) => {
                    if (blob) {
                        new FileSaver.saveAs(blob, filename, {autoBom: false});
                    } else {
                        this.snackBar.open("No data available", undefined, {
                            duration: 5000,
                            panelClass: ["warning"]
                        });
                    }
                }
            );
    }

    downloadByCustomer() {
        const exportParams = this.getPdfExportParams(this.pdfExportModel);
        const filename = `Timesheets ${
            this.pdfExportModel.customer.id
        }, ${this.getFilenameTimestamp(exportParams)}.zip`;

        this.reportingService
            .downloadReportByCustomer(
                this.pdfExportModel.customer.id,
                exportParams
            )
            .subscribe(
                (blob) => {
                    if (blob) {
                        new FileSaver.saveAs(blob, filename, {autoBom: false});
                    } else {
                        this.snackBar.open("No data available", undefined, {
                            duration: 5000,
                            panelClass: ["warning"]
                        });
                    }
                }
            );
    }

    downloadExcelExport() {
        const exportParams = this.getExcelExportParams(this.excelExportModel);
        const filename = `Excel-Timesheets_${this.getFilenameTimestamp(
            exportParams
        )}.xlsx`;

        this.reportingService
            .downloadExcelExport(exportParams)
            .subscribe(
                (blob) => {
                    if (blob) {
                        new FileSaver.saveAs(blob, filename, {autoBom: false});
                    } else {
                        this.snackBar.open("No data available", undefined, {
                            duration: 5000,
                            panelClass: ["warning"]
                        });
                    }
                }
            );
    }

    getFilenameTimestamp(exportParams: ExportParams): String {
        return `${exportParams.startDay}.${exportParams.startMonth}.${exportParams.startYear}-${exportParams.endDay}.${exportParams.endMonth}.${exportParams.endYear}`;
    }

    getExportParams(exportModel: ExportModel): ExportParams {
        const params: ExportParams = new ExportParams();
        params.startDay = exportModel.start.date();
        params.startMonth = exportModel.start.month() + 1;
        params.startYear = exportModel.start.year();

        params.endDay = exportModel.end.date();
        params.endMonth = exportModel.end.month() + 1;
        params.endYear = exportModel.end.year();

        return params;
    }

    getPdfExportParams(exportModel: PdfExportModel): PdfExportParams {
        const exportParams = this.getExportParams(
            exportModel
        ) as PdfExportParams;
        exportParams.locale = exportModel.locale;
        exportParams.custSig = exportModel.custSig;
        exportParams.ownSig = exportModel.ownSig;

        return exportParams;
    }

    getExcelExportParams(exportModel: ExcelExportModel): ExcelExportParams {
        const exportParams = this.getExportParams(
            exportModel
        ) as ExcelExportParams;
        exportParams.customerIds = exportModel.customers.map(
            (customer) => customer.id
        );

        return exportParams;
    }

    setStartAndEndDateLastMonth(exportModel: ExportModel) {
        exportModel.start = moment().subtract(1, "month").startOf("month");
        exportModel.end = moment().subtract(1, "month").endOf("month");
    }

    setStartAndEndDateCurrentMonth(exportModel: ExportModel) {
        exportModel.start = moment().startOf("month");
        exportModel.end = moment().endOf("month");
    }

    setStartAndEndDateCurrentQuarter(exportModel: ExportModel) {
        exportModel.start = moment().startOf("quarter");
        exportModel.end = moment().endOf("quarter");
    }

    setStartAndEndDateLastQuater(exportModel: ExportModel) {
        exportModel.start = moment().subtract(1, "quarter").startOf("quarter");
        exportModel.end = moment().subtract(1, "quarter").endOf("quarter");
    }

    setStartAndEndDateCurrentHalfYear(exportModel: ExportModel) {
        const timeRange = TimeRangeUtil.getHalfYear(moment());
        exportModel.start = timeRange.start;
        exportModel.end = timeRange.end;
    }

    setStartAndEndDateLastHalfYear(exportModel: ExportModel) {
        const timeRange = TimeRangeUtil.getHalfYear(
            moment().subtract(2, "quarter")
        );
        exportModel.start = timeRange.start;
        exportModel.end = timeRange.end;
    }

    setStartAndEndDateCurrentYear(exportModel: ExportModel) {
        exportModel.start = moment().startOf("year");
        exportModel.end = moment().endOf("year");
    }

    setStartAndEndDateLastYear(exportModel: ExportModel) {
        exportModel.start = moment().subtract(1, "year").startOf("year");
        exportModel.end = moment().subtract(1, "year").endOf("year");
    }
}

class ExportModel {
    start: Moment;
    end: Moment;
}

export class PdfExportModel extends ExportModel {
    public user: UserDetails;
    public customer: Customer;
    public locale: string = "de-DE";
    public custSig: boolean = true;
    public ownSig: boolean = true;
}

class ExcelExportModel extends ExportModel {
    customers: Customer[];
}
