<div
    class="navbar mat-elevation-z5"
    style="z-index: 10"
    fxLayoutAlign="center center"
>
    <div fxLayout="row" class="appContent">
        <ng-container *ngIf="user">
            <!--            <button mat-button routerLink="/home">Home</button>-->
            <button mat-button routerLink="/timesheet">Timesheet</button>
            <button mat-button routerLink="/projects">My Projects</button>
            <button mat-button [matMenuTriggerFor]="absenceMenu">
                Absences
            </button>
            <button mat-button [matMenuTriggerFor]="adminMenu">
                Administration
            </button>
        </ng-container>

        <div fxLayout="row" fxFlexOffset="auto">
            <button id="user-btn" mat-mini-fab [matMenuTriggerFor]="menu">
                <img
                    id="user-img"
                    *ngIf="user"
                    [src]="this.userImgAsBase64"
                    [alt]="userInitials || ''"
                />
                <mat-icon *ngIf="!user">login</mat-icon>
            </button>
            <mat-menu #menu="matMenu">
                <mat-card appearance="outlined" class="mat-elevation-z0">
                    <mat-card-title>{{
                            user?.displayName || "Not logged in"
                        }}
                    </mat-card-title>
                    <mat-card-subtitle>{{
                            user?.principalId || "Please log in below."
                        }}
                    </mat-card-subtitle>
                    <mat-card-actions>
                        <button mat-button (click)="login()" *ngIf="!user">
                            <mat-icon>login</mat-icon>
                            <span>Login (SSO)</span>
                        </button>
                        <button mat-menu-item (click)="logout()" *ngIf="user">
                            <mat-icon>logout</mat-icon>
                            <span>Logout</span>
                        </button>
                    </mat-card-actions>
                </mat-card>
            </mat-menu>
        </div>
    </div>
</div>

<mat-menu #adminMenu="matMenu">
    <button
        mat-menu-item
        routerLink="/manage/users"
        *ngIf="userAdminGuard.canActivateRs | async"
    >
        Users
    </button>
    <button
        mat-menu-item
        routerLink="/manage/regions"
        *ngIf="userAdminGuard.canActivateRs | async"
    >
        Regions
    </button>
    <button
        mat-menu-item
        routerLink="/manage/project-roles"
        *ngIf="projectAdminGuard.canActivateRs | async"
    >
        Project Roles
    </button>
    <mat-divider></mat-divider>
    <button
        mat-menu-item
        routerLink="/manage/customers"
        *ngIf="customerAdminGuard.canActivateRs | async"
    >
        Customers
    </button>
    <button
        mat-menu-item
        routerLink="/manage/projects"
        *ngIf="projectAdminGuard.canActivateRs | async"
    >
        Projects
    </button>

    <mat-divider></mat-divider>
    <button
        mat-menu-item
        routerLink="/manage/reporting"
        *ngIf="reportingGuard.canActivateRs | async"
    >
        Reporting
    </button>
    <button
        mat-menu-item

        [matMenuTriggerFor]="adminAbsencesMenu"
        *ngIf="hrGuard.canActivateRs | async"
    >
        Absences
    </button>
</mat-menu>

<mat-menu #absenceMenu="matMenu">
    <button mat-menu-item routerLink="/absence">Overview</button>
    <button mat-menu-item [matMenuTriggerFor]="selfServiceMenu">
        Self Service
    </button>
</mat-menu>

<mat-menu #selfServiceMenu="matMenu">
    <button mat-menu-item routerLink="/absence/self-service">Overview</button>
    <mat-divider></mat-divider>
    <button mat-menu-item (click)="openCreateVacationRequestModal()">
        Vacation Request
    </button>
</mat-menu>

<mat-menu #adminAbsencesMenu="matMenu">
    <button
        mat-menu-item
        routerLink="/manage/available-vacation-days"
        *ngIf="hrGuard.canActivateRs | async"
    >
        Vacation Day Management
    </button>
    <button
        mat-menu-item
        routerLink="/manage/holidays"
        *ngIf="userAdminGuard.canActivateRs | async"
    >
        Public Holidays
    </button>
    <mat-divider></mat-divider>
    <button
        mat-menu-item
        routerLink="/manage/absences"
        *ngIf="hrGuard.canActivateRs | async"
    >
        Absence Management
    </button>

</mat-menu>

<div fxLayout="row" fxLayoutAlign="center">
    <div class="appContent">
        <router-outlet></router-outlet>
    </div>
</div>

<div *ngIf="!backendService.isAvailable" class="loading">
    <h1>Waiting for backend...</h1>
    <mat-spinner></mat-spinner>
</div>
