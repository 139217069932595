import { Component, OnInit } from "@angular/core";
import {
    AbsenceFilter,
    AbsenceFilterParams,
    AbsenceService,
} from "../absence.service";
import { UserDetails } from "../../users/userDetails";
import * as moment from "moment";
import { UserService } from "../../users/user.service";
import {
    SchedulerRow,
    SchedulerRowContent,
} from "../../scheduler/scheduler.component";
import { flatMap, groupBy, map, toArray } from "rxjs/operators";
import { of, zip } from "rxjs";
import { Observable } from "rxjs/Observable";
import "rxjs/add/observable/from";
import { AbsenceModel } from "../absence";
import {TimeRange} from '../../common';

@Component({
    selector: "app-absence-overview",
    templateUrl: "./absence-overview.component.html",
    styleUrls: ["./absence-overview.component.sass"],
})
export class AbsenceOverviewComponent implements OnInit {
    private absencesFilter: AbsenceFilter;
    private availableUsers: UserDetails[];
    year: number;
    schedulerRows: SchedulerRow[];

    constructor(
        private absenceService: AbsenceService,
        private userService: UserService
    ) {
        this.absencesFilter = new AbsenceFilter();
        this.year = moment().year();
    }

    ngOnInit(): void {
        this.userService.readActiveUsers().subscribe((user) => {
            this.availableUsers = user;
            this.absencesFilter.selectedUser = this.availableUsers.map(
                (value) => value.principalId
            );
            this.reloadAbsences();
        });
    }

    reloadAbsences(): void {
        const absenceFilterParams = new AbsenceFilterParams();
        this.absencesFilter.selectedTimeRange = new TimeRange(
            moment().set("year", this.year).startOf("year"),
            moment().set("year", this.year).endOf("year")
        );
        absenceFilterParams.userIds = this.absencesFilter.selectedUser;

        absenceFilterParams.startDay =
            this.absencesFilter.selectedTimeRange.start.date();
        absenceFilterParams.startMonth =
            this.absencesFilter.selectedTimeRange.start.month() + 1;
        absenceFilterParams.startYear =
            this.absencesFilter.selectedTimeRange.start.year();

        absenceFilterParams.endDay =
            this.absencesFilter.selectedTimeRange.end.date();
        absenceFilterParams.endMonth =
            this.absencesFilter.selectedTimeRange.end.month() + 1;
        absenceFilterParams.endYear =
            this.absencesFilter.selectedTimeRange.end.year();

        this.absenceService
            .readAllAbsencesByUserAndTimerange(absenceFilterParams)
            .pipe(
                flatMap((value) => Observable.from(value)),
                map((value) => AbsenceModel.fromAbsenceDTO(value)),
                groupBy((absence) => absence.user.principalId),
                flatMap((group) =>
                    zip(
                        of(group.key),
                        group.pipe(
                            map((absence) => {
                                return <SchedulerRowContent>{
                                    title: '',
                                    start: absence.start,
                                    end: absence.end,
                                    clickable: false,
                                };
                            }),
                            toArray()
                        )
                    )
                ),
                map((zippedGroup) => {
                    return <SchedulerRow>{
                        name: this.availableUsers.find(
                            (value) => value.principalId === zippedGroup[0]
                        ).displayName,
                        information: zippedGroup[0],
                        content: zippedGroup[1],
                    };
                }),
                toArray()
            )
            .subscribe((schedulerRows) => {
                this.schedulerRows = schedulerRows;
            });
    }
}
